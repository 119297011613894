import * as React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

const UpdateDetailsPage = () => (
    <Layout>
        <Seo title={'Update My Details | Existing Customers | Nemo Personal Finance'} description={'For information on how Nemo loans customers can get in touch with us to update their contact/ bank details or to change their monthly repayments click here.'} lang={'en-gb'} />
        <div className="threeCol purple cfx">
            <aside id="leftCol">
                <div id="tNav">
                    <ul>
                        <li><a href="/customer-service/moving-home">How your Nemo mortgage
                            works</a></li>
                        <li><a href="/customer-service/remortgaging">What to do if you want to
                            remortgage </a></li>
                        <li><a href="/customer-service/settle-my-second-charge-mortgage">Settle
                            my Nemo mortgage early</a></li>
                        <li><a href="/customer-service/update-my-details" className={'selected'}>Update my details</a>
                        </li>
                    </ul>
                </div>
            </aside>
            <section id="rightCol">
                <div className="contCol">
                    <div className="contColInr">
                        <div className="bubble purple cfx">
                            <div className="inr">
                                <h1>Update my details</h1>
                            </div>
                            <div className="bot"></div>
                        </div>
                        <p className="leading"></p>
                        <p>If you have had a change in circumstances, and would like to update us, please <a
                            href="/contact-us">contact us</a> <br /><br />You should contact us as soon as possible, if you wish to change
                                any of the following:</p>
                        <ul>
                            <li>
                                <strong>Changing your monthly repayment date <br /></strong>
                                <br />
                            </li>
                            <li>
                                <strong>Changing your bank details <br /></strong>
                                <br />
                            </li>
                            <li>
                                <strong>Changing your contact/address details <br /><br /></strong>
                            </li>
                            <li>
                                <strong>Change of name by Deed Poll<br /></strong>
                                <br />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="fbCol">
                    {/*<div className="fbList">*/}
                    {/*    <ul>*/}
                    {/*        <li className="phone">*/}
                    {/*            <div className="top"></div>*/}
                    {/*            <div className="txtWrap">*/}
                    {/*                <h3>Want us to call you?</h3>*/}
                    {/*                <p>If you're moving house and want us to call you, <a*/}
                    {/*                    href="/customer-service/callback-request">please*/}
                    {/*                    give us your details.</a></p>*/}
                    {/*            </div>*/}
                    {/*        </li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                </div>
            </section>
        </div>
    </Layout>
)

export default UpdateDetailsPage;